/** @format */

import React from "react";
import GlobalLayout from "../components/layouts/GlobalLayout";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import SiteHeader from "../components/SiteHeader/SiteHeader";
import Footer from "../components/Footer/Footer";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import {
    StyledContactSection,
    StyledLeftWrapper,
    StyledRightWrapper,
    StyledKontaktImage,
    StyledNumberWrapper,
    StyledEmail,
} from "../components/Kontakt/StyledContact";

const Kontakt = () => {
    const data = useStaticQuery(graphql`
        query KontaktQuerry {
            datoCmsKontakt {
                kontaktCzwartaLinia
                kontaktDrugaLinia
                kontaktNaglowek
                kontaktPierwszaLinia
                kontaktTrzeciaLinia
                telefon2
                telefon1
                email
                zdjecie {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
            }
        }
    `);

    const image = getImage(data.datoCmsKontakt.zdjecie);

    return (
        <GlobalLayout>
            <HelmetTemplate
                title="Kontakt"
                desc="Skontaktuj się z Agroturystyką Przystanek na Leśnej"
            />
            <SiteHeader />
            <StyledContactSection>
                <StyledLeftWrapper>
                    <h2>{data.datoCmsKontakt.kontaktNaglowek}</h2>
                    <p class="m-top">
                        {data.datoCmsKontakt.kontaktPierwszaLinia}
                    </p>
                    <p>{data.datoCmsKontakt.kontaktDrugaLinia}</p>
                    <p>{data.datoCmsKontakt.kontaktTrzeciaLinia}</p>
                    <p>{data.datoCmsKontakt.kontaktCzwartaLinia}</p>
                    <StyledNumberWrapper>
                        <p>Telefon: {data.datoCmsKontakt.telefon1}</p>
                        <p>{data.datoCmsKontakt.telefon2}</p>
                    </StyledNumberWrapper>
                    <StyledEmail>
                        <p>E-mail: {data.datoCmsKontakt.email}</p>
                    </StyledEmail>
                </StyledLeftWrapper>
                <StyledRightWrapper>
                    <StyledKontaktImage
                        image={image}
                        alt="Przystanek na Leśnej"
                    />
                </StyledRightWrapper>
            </StyledContactSection>
            <Footer />
        </GlobalLayout>
    );
};

export default Kontakt;
