import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledContactSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 110px 40px;
    max-width: 1240px;
    margin: 0 auto;
    @media only screen and (max-width: 768px){
        padding-top: 60px;
        padding-bottom: 40px;
    }
    @media only screen and (max-width: 430px){
        padding: 60px 20px 40px 20px;
    }
`   
export const StyledLeftWrapper = styled.div`
    width: 50%;
    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 160.7%;
    }
    > h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 160.7%;
        color: #F05518;
        padding-bottom: 40px;
    }
    @media only screen and (max-width: 1114px){
        p{
            font-size: 22px;
        }
        > h2{
            font-size: 40px;
        }
    }
    @media only screen and (max-width: 826px){
        p{
            font-size: 16px;
        }
        > h2{
            font-size: 32px;
        }
    }
    @media only screen and (max-width: 767px){
        width: 100%;
        text-align: center;
        p{
            font-size: 20px;
        }
        > h2{
            font-size: 38px;
        }
    }
    @media only screen and (max-width: 432px){
        p{
            font-size: 16px;
        }
        > h2{ 
            font-size: 30px;
        }
    }
    @media only screen and (max-width: 350px){
        > h2{
            font-size: 26px;
        }
    }
`
export const StyledRightWrapper = styled.div`
    width: 50%;
    max-width: 521px;
    @media only screen and (max-width: 1114px){
        max-width: 400px;
    }
    @media only screen and (max-width: 870px){
        width: 44%;
    }
    @media only screen and (max-width: 768px){
        display: none;
    }
`
export const StyledKontaktImage = styled(GatsbyImage)`
    width: 100%;
    max-width: 400px;
    height: 100%;
    box-shadow: 13px 8px 17px 6px rgba(0, 0, 0, 0.42);
    border-radius: 22px;
`

export const StyledNumberWrapper = styled.div`
    margin-top: 40px;
    > p {
        &:last-child{
            margin-left: 102px;
        }
    }
    @media only screen and (max-width: 1114px){
        > p {
            &:last-child{
                margin-left: 82px;
            }
        }
    }
    @media only screen and (max-width: 826px){
        > p {
            &:last-child{
                margin-left: 59px;
            }
        }
    }
    @media only screen and (max-width: 767px){
        > p {
            &:last-child{
                margin-left: 85px;
            }
        }
    }
    @media only screen and (max-width: 432px){
        > p {
            &:last-child{
                margin-left: 70px;
            }
        }
    }
`

export const StyledEmail = styled.div`
    margin-top: 40px;
`